
import missionController from "./mission_controller.js";

const DURATION_DROPDOWN = 2000;
const DURATION_CHK_ANIM = 250;

const PAD_TIME = 500;


missionController.subscribe('data-ready', missionData => {
    const alias = missionData.changedAlias;
    const stepIndex = missionData.changedStep;
    const unlockedSteps = missionData.stepsUnlocked;  // These are steps changing from locked to unlocked, not the total number of unlocked steps.
    if(alias && stepIndex !== undefined) {
        const promises = [];

        const isMissionComplete = missionData.getLatestCompletedStepCountByAlias(alias) === missionData.getTotalStepCountByAlias(alias);

        promises.push(completeStepFeedback(alias, stepIndex, unlockedSteps.length === 0 && !isMissionComplete));
        promises.push(unlockedStepsFeedback(alias, unlockedSteps));
        promises.push(incrementProgressBar(alias, missionData.getLatestCompletedStepCountByAlias(alias)));

        if(isMissionComplete) {
            promises.push(celebrateCompletion(alias));
        }

        Promise.all(promises).then(() => {
            missionController.publish('feedback-complete');
        });
    } else {
        missionController.publish('feedback-complete');
    }
});

function completeStepFeedback(alias, stepIndex, scrollIntoView) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            missionController.publish('step_progress-complete', { alias, stepIndex, scrollIntoView });
            missionController.subscribe('step_feedback-complete', () => {
                resolve();
            });
        }, DURATION_DROPDOWN + PAD_TIME);
    });
}

function unlockedStepsFeedback(alias, unlockedSteps) {
    const promises = [];
    unlockedSteps.forEach(stepIndex => {
        promises.push(new Promise((resolve, reject) => {
            setTimeout(() => {
                missionController.publish('step_progress-unlock', { alias, stepIndex });
                missionController.subscribe('step_feedback-complete', () => {
                    resolve();
                });
            }, DURATION_DROPDOWN + PAD_TIME * 2);
        }));
    });
    return Promise.all(promises);
}

function incrementProgressBar(alias, latestCompletedCount) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            missionController.publish('progress-increment', { alias, latestCompletedCount });
            missionController.subscribe('progress_feedback-complete', () => {
                resolve();
            });
        }, DURATION_DROPDOWN + PAD_TIME);
    });
}

function celebrateCompletion(alias) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            missionController.publish('progress-complete', { alias });
            missionController.subscribe('progress_feedback-complete', () => {
                resolve();
            });
        }, DURATION_DROPDOWN + PAD_TIME);
    });
}

export { 
    DURATION_DROPDOWN,
    DURATION_CHK_ANIM,
    completeStepFeedback
};


